import React from "react"

import "../../styles/globals.scss"

function Quote() {
  return (
    <div className="layout-wrapper">
      <section className="bg-white section-gap">
        <div className="row justify-content-center mb-4">
          <div className="col-12 mb-4 d-flex justify-content-center align-items-center">
            <h2 className="font-bold d-block text-center mr-4">
              Call us for a cremation quote.
            </h2>
          </div>
          <div className="col-12 col-md-9">
            <p className="text-center">
              You don’t need an expensive funeral service to say goodbye.
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Quote
